<template>
	<div class="col-12">
		<div class="card">
			<h5>Histórico de Pedido</h5>

			<Message severity="info" :closable="false">Clique no <strong>pedido</strong> para visualizar todos os itens do pedido.</Message>

			<div>
				<div class="formgroup-inline">
					<div>
						<div class="col-12 md:col-12">
							<Calendar :showIcon="true" :showButtonBar="true" v-model="dataInicial" dateFormat="dd/mm/yy"></Calendar>
						</div>
					</div>  
					<div>
						<div class="col-12 md:col-12">
							<Calendar :showIcon="true" :showButtonBar="true" v-model="dataFinal" dateFormat="dd/mm/yy"></Calendar> 
						</div>
					</div>
					<div>
						<div class="col-12 md:col-10">
							<Button label="Consultar" @click="getPedidos()"></Button> 
						</div>
					</div>
					
				</div>
			</div>


			<DataTable :value="pedidos" :paginator="true" class="p-datatable-gridlines" style="text-align: center" :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack" breakpoint="800px"
					:globalFilterFields="['numeroPedido','situacao','safra', 'dataImplantacao']" >
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
						</span>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Loading customers data. Please wait.
				</template>
				<Column style="text-align: center; min-width:2rem">
					<template #body="data">
						<Button @click="$emit('showDetail', true, data.data.todosItens, data.data.valorTotal, data.data.numeroPedido)" type="button" class="mr-2" label="Detalhar" icon="pi pi-search"></Button>
					</template>
				</Column>
				<Column field="numeroPedido" header="Núm Pedido" style="text-align: center; min-width:2rem">
					<template #body="data">
						{{data.data.numeroPedido}}
					</template>
				</Column>
				<Column field="dataImplantacao" header="Data" style="text-align: center; min-width:2rem">
					<template #body="{data}">
						{{formatDate(data.dataImplantacao)}}
					</template>
				</Column>
				<Column field="situacao" header="Situação" style="text-align: center; min-width:2rem">
					<template >
						{{situacao}}
					</template>
				</Column>
				<Column field="safra" header="Safra" style="text-align: center; min-width:2rem">
					<template >
						{{safra}}
					</template>
				</Column>
				<Column field="valorTotal" header="Total em aberto (R$)" style="text-align: right; min-width:2rem">
					<template  #body="{data}">
						<span class="text-bold">{{formatCurrency(data.valorTotal)}}</span>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
	
</template>

<script>
	
    import Formatacao from '../../utilities/Formatacao';
	import {FilterMatchMode,FilterOperator} from 'primevue/api';
	import HistoricoPedidoService from '../../service/HistoricoPedidoService';

	export default {
        props: {
			matricula: {
				type: Number,
				required: true
			}
		},

		data() {
			return {
				filters1: null,
				loading1: true,
				loading2: true,
				idFrozen: false,
				expandedRows: [],
                dataInicial: Formatacao.subtrairMeses(new Date(), 3),
                dataFinal: new Date(),
                pedidos: []
			}
		},

		created() {
			this.initFilters1();
            this.getPedidos();
		},
		mounted() {
			this.loading1 = false;
			this.loading2 = false;			
		},
		methods: {
			initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'codigoEstabelecimento': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'numeroNotaFechamento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
					'numeroRe': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
					'safra': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
					'codigoItem': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
					'dataImplantacao': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
				
				}
			},
            getPedidos() {
                HistoricoPedidoService.getPedidos(
                    this.$props.matricula,
                    Formatacao.formatDateCustom(this.dataInicial, 'DD-MM-YYYY'),
                    Formatacao.formatDateCustom(this.dataFinal, 'DD-MM-YYYY')
                ).then((response) => {
                    this.pedidos = response.data;
                    
                });
            },
			clearFilter1() {
				this.initFilters1();
			},

			formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
			},
			formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
			},
			formatDate(data) {                            
            	return Formatacao.formatDate(data);
			}

		}
	}
</script>


<style>   
    @import '../../assets/css/master.scss';    
	@import '../../assets/demo/styles/badges.scss';


	.p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }
</style>