<template>
    <div class="col-12">
        <div class="card">
            <h5>Itens do Pedido: {{numeroPedido}}</h5>
			<DataTable :value="todosItens" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
					:globalFilterFields="['descricao','valor','quantidade','quantidadeAtendida']" >
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<div>
							<Button @click="$emit('showDetail', false)" icon="pi pi-backward" type="button" class="p-button-outlined mb-2" label="Voltar"/>
							<Button type="button" icon="pi pi-download" label="Exportar" class="p-button-outlined mb-2" @click="exportar()"/>
							<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
						</div>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
						</span>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>
				<Column field="descricao" header="Item" :sortable="true" style="min-width:12rem">
					<template>
						{{descricao}}
					</template>
				</Column>
				<Column field="valor" header="Valor unitário" :sortable="true" style="text-align: right;  min-width:12rem">
					<template #body="{data}">
						{{formatCurrency(data.valor)}}
					</template>
				</Column>
				<Column field="quantidade" header="Quantidade" :sortable="true" style="text-align: right; min-width:10rem">
					<template>
						{{quantidade}}
					</template>
				</Column>
				<Column field="valorTotal" header="Valor total" :sortable="true" style="text-align: right; min-width:12rem">
					<template #body="{data}">
						{{formatCurrency(data.valorTotal)}}
					</template>
				</Column>
				<Column field="quantidadeAtendida" header="Qtd atendido" :sortable="true" style="text-align: right; min-width:10rem">
					<template >
						{{quantidadeAtendida}}
					</template>
				</Column>

				<ColumnGroup type="footer">
					<Row>
						<Column footer="Total em aberto (R$):" :colspan="3" footerStyle="text-align: right;"/>
						<Column :footer="formatCurrency(valorTotalPedido)" footerStyle="text-align: right;"/>
						<Column />
					</Row>
				</ColumnGroup>
			</DataTable>
            
        </div>
    </div>

</template>

<script>
    import {FilterMatchMode} from 'primevue/api';
	import Formatacao from '../../utilities/Formatacao';
	import ExportarPlanilha from '../../utilities/ExportarPlanilha';

    export default {
        name: 'HistoricoPedidosDetalhado', 

		props: {
			matricula: {
				type: Number,
				required: true
			},
			todosItens: {
				type: Array,
				required: true
			},
            valorTotalPedido: {
                type: Number,
				required: true
            },
			numeroPedido: {
                type: Number,
				required: true
			}
		},
        data(){
            return {
				filters1: null,
				loading1: true,
				loading2: true
            }
        },

        created(){
            this.initFilters1();
            this.getTodosItens();
        },
		mounted() {
			this.loading1 = false;
			this.loading2 = false;
		},
        methods: {
            initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'descricao': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'valor': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'quantidade': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'quantidadeAtendida': {value: null, matchMode: FilterMatchMode.CONTAINS}			
				}
			},
            getTodosItens() {
                this.$props.todosItens = this.$props.todosItens.map((item) => {
                    item["valorTotal"] = item.quantidade * item.valor;

					return item;
                });
            },
			exportar() {
				const colunas = [
					{name: "descricao", title: "Item"},
					{name: "valor", title: "Valor unitário"},
					{name: "quantidade", title: "Quantidade"},
					{name: "valorTotal", title: "Valor total"},
					{name: "quantidadeAtendida", title: "Qtd atendido"}
				];

				ExportarPlanilha({
					cols: colunas,
					rows: this.$props.todosItens,
					types:{
						valor: 'n',
						valorTotal: 'n',
						quantidade: 'n',
						quantidadeAtendida: 'n'
					},
					formats:{
						valor: '_-R$ * #,##0.00_-;-R$ * #,##0.00_-;_-R$ * "-"??_-;_-@_-',
						valorTotal: '_-R$ * #,##0.00_-;-R$ * #,##0.00_-;_-R$ * "-"??_-;_-@_-'
					},
					filename: 'portal_cooperado.xls',
					bookType: 'biff8'
				});
			},
			clearFilter1() {
				this.initFilters1();
			},
			formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {

				if (isNaN(value)) {
					return '***********';
				}

				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
			},
			formatDate(data) {                            
            	return Formatacao.formatDate(data);
			}

        }
       
    }
    
</script>

<style scoped lang="scss">
    @import '../../assets/demo/styles/badges.scss';

    button{
		margin-right: 8px;
	}

	.label-blue{
		color: #0066cc;
	}
	.label-red{
		color: #B40404;
	}
</style>
