<template>
    <HistoricoPedidosResumido v-if="!showDetalhe" v-on:showDetail="showDetail" :matricula="currentUser.matricula"/>
    <HistoricoPedidosDetalhado v-if="showDetalhe" v-on:showDetail="showDetail" :matricula="currentUser.matricula"
                                                    :todosItens="todosItens"
                                                    :valorTotalPedido="valorTotalPedido"
                                                    :numeroPedido="numeroPedido" />
</template>

<script lang="js">

    import HistoricoPedidosResumido from '../../components/consulta/HistoricoPedidosResumido.vue';
    import HistoricoPedidosDetalhado from '../../components/consulta/HistoricoPedidosDetalhado.vue';

    export default {
        data(){
            return {
                showDetalhe: false,
                todosItens: [],
                valorTotalPedido: 0,
                numeroPedido: 0
            }
        },
        methods: {
            showDetail(value, todosItens, valorTotalPedido, numeroPedido) {
                this.showDetalhe = value;
                if(value){
                    this.todosItens = todosItens;
                    this.valorTotalPedido = valorTotalPedido;
                    this.numeroPedido = numeroPedido;
                }
            }
        },
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { HistoricoPedidosResumido, HistoricoPedidosDetalhado }
    }

</script>