import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/pedidos`;

class HistoricoVendaService {
    getPedidos(matricula, dataInicial, dataFinal) {
        return axios.get(API_BASE_URL +RESOURCE_V1 + "/completo/" + matricula + "/" + dataInicial + "/" + dataFinal);
    }
}

export default new HistoricoVendaService();